<template>
  <div id="feeding">
    <div class="colorPad">
      <div class="header">
         <div class="times">
            <div style="background-color: rgba(255,255,255,0.1);width: 16rem;height: 16rem;border-radius:50%;display: flex;justify-content: center;align-items: center;">
              <div style="background-color: rgba(255,255,255,0.2);width: 80%;height: 80%;border-radius:50%;display: flex;justify-content: center;align-items: center;">
                <div style="background-color: #fff;width: 75%;height: 75%;border-radius:50%;display: flex;justify-content: center;align-items: center;flex-direction: column;">
                  <label class="num">9</label><label class="unit">份</label>
                  <div class="weight">
                      <label class="text">约40g</label>
                  </div>
                </div>
              </div>
            </div>
        </div>
         <div class="detail btn-blank">
            <label class="text">查看详情</label>
         </div>
      </div>
      <div class="box">
        <div class="head">自动喂食计划
        </div>
         <div class="body" style="display:flex;flex-direction: column;">
            <div class="plan">
              <label class="time">06:00</label>
              <label class="weight">3份（约15g）</label>
            </div>
            <!-- <div class="plan">
              <label class="time">12:00</label>
              <label class="weight">3份（约15g）</label>
            </div>
             <div class="plan">
              <label class="time">18:00</label>
              <label class="weight">3份（约15g）</label>
            </div> -->
        </div>
      </div>
      <div class="box">
        <div class="head">手动放粮</div>
        <div class="body" style="display:flex;justifyContent:center;padding-top: 1rem;padding-bottom:2rem;">
            <button @click="feeding" class="btn-blank btn-primary" style="width: 90%;">放粮</button>
        </div>
      </div>
      <div class="box">
        <div class="head">手动放水</div>
        <div class="body" style="display:flex;justifyContent:center;padding-top: 1rem;padding-bottom:2rem;">
            <button @click="water" class="btn-blank btn-orange" style="width: 90%;">放水</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Icon,showConfirmDialog } from 'vant';
  import CommonService from '../../services/CommonService'
  import 'vant/lib/index.css';
  export default {
    name: "feeding",
    computed: {},
    data() {
      return {
        token:'',
        deviceID:'0',//设备的唯一ID
        userDeviceID:'0',//跟用户绑定之后的ID
      };
    },
    computed:{
    },
    methods: {
      goHome: function () {
        this.$router.push({ name: "index" });
      },
      feeding:function(){
        showConfirmDialog({
          title: '放粮确认',
          message:
            '确定要放粮吗？',
          })
          .then(() => {
            CommonService.SendMessage(this.deviceID,{Topic:'',Payload:{Action:'feeding',Extra:'1'}});
          })
          .catch(() => {
            // on cancel
          });
      },
      water:function(){
        showConfirmDialog({
          title: '放水确认',
          message:
            '确定要放水吗？',
          })
          .then(() => {
            CommonService.SendMessage(this.deviceID,{Topic:'',Payload:{Action:'water',Extra:'1'}});
          })
          .catch(() => {
            // on cancel
          });
        // this.message.confirm('',()=>{
        //   CommonService.SendMessage(this.deviceID,{Topic:'',Payload:{Action:'feeding',Extra:'1'}});
        // },()=>{
        //     this.message.hide();
        // });
        // showDialog({
        //   message: '确定要出粮吗?',
        // }).then(() => {
        //   // CommonService.SendMessage(this.deviceID,{Topic:'',Payload:{Action:'feeding',Extra:'1'}});
        // });
      }
    },
    created: function () {},
    mounted: function () {
      if(!this.$route.query.token){
          alert('invalid token');
      }
      if(!this.$route.query.id){
          alert('invalid deviceid');
      }
      this.token=this.$route.query.token;
      //该ID为设备与用户绑定之后的ID,并不是设备ID
      this.userDeviceID=this.$route.query.id;
      //设置token
      this.$store.dispatch('user/setToken',this.token);
      //获取设备状态信息
      CommonService.GetDeviceStatus(this.userDeviceID).then(s=>{
        if(s.Success){
           if(s.Data){
              //返回格式{DeviceID:0,IsOnline:1,Status:''}
              this.deviceID=s.Data.DeviceID;
              this.isOnling=s.Data.IsOnline;
              // if(!s.Data.IsOnline){
              //   alert('设备已离线');
              // }
              // var deviceStatus=s.Data.Status;
              // if(deviceStatus.power){
              //   this.power=deviceStatus.power=='on'?1:0;
              //   CommonService.SendMessage(this.deviceID,{Topic:'',Payload:{Action:'power',Extra:this.power==0?'off':'on'}});
              // }
           }
        }
        else{
          alert(s.Message);
        }
      });
    },
    components: { 
      [Icon.name]: Icon,
      // [Dialog.Component.name]: Dialog.Component
    },
  };
</script>
<style lang="scss">
// @import '@radial-color-picker/vue-color-picker/dist/vue-color-picker.css';
#feeding {
  background: #fff;
}
.header{
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction: column;
  .times{
      display: flex;
      .num{
        color:#3383FA;
        font-size: 4rem;
        font-weight: 600;
      }
      .unit{
        font-size: 0.8rem;
        color:#3383FA;
        font-weight: 500;
      }
      .weight{
        margin-top: 0.3rem;
        .text{
          color:#3383FA;
          font-size: 0.8rem;
        }
      }
  }
  .detail{
       margin-top: 2.2rem;
   }
}
.plan{
  padding: 20px 5px;
  width: 100%;
  display: flex;
  .time{
      flex: 1;
      font-size: 16px;
      color: #666;
  }
  .weight{
      width: 100px;
      font-size: 14px;
      color: #999;
  }
}
.btn-primary{
    background: #3383FA;
}
.btn-orange{
  background: orange;
}
.btn-blank{
    margin-top: 10px;
    border-radius: 50px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding:0.5rem;
    width: 60%;
    text-align: center;
    border:solid 1px #fff;
}
.colorPad {
  background: pink;
  background:-webkit-linear-gradient(#3383FA, #62B9FD);
  background:-o-linear-gradient(#3383FA, #62B9FD);
  background:-moz-linear-gradient(#3383FA, #62B9FD);
  background: linear-gradient(#3383FA, #62B9FD);
  height: 100%;
  overflow: hidden;
  padding-top: 5.2rem;
  .box {
    border-radius: 10px;
    min-height: 100px;
    background: #fff;
    margin: 20px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  .box .head {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    padding:0px 20px;
    align-items: center;
    border-bottom: #eee 1px solid;
  }
  .box .body {
    display: flex;
    justify-content: flex-start;
    padding:0 20px 10px 20px;
    align-items: center;
    flex: 1;
  }
  .box:first-child {
    margin-top: 0;
  }
}
</style>
